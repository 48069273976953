import * as React from "react"

import Layout from "../components/layout"
import "../components/contact.css"
import HeaderLight from "../components/headerlight"
import Artwork from "../components/artwork.js"
import Seo from "../components/seo"

const Contact = () => (
    
    <Layout>
      <HeaderLight/>
      <div className="Hero">
        <div className="HeroGroup">
        <h1>You can reach me at:</h1>
        <h2> I solve problems.</h2>
        <p>email: denizonmus@tutanota.com</p>
        </div>
      </div>
    </Layout>
  )

export const Head = () => <Seo title="Contact" /> 


export default Contact